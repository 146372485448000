<template>
	<main class="vcc-main" v-if="formType == 'expert'">
		<h3 class="heading heading__hr">Задать вопрос эксперту</h3>
		<ask-question-form :is_expert_form="true"/>
	</main>
	<main class="vcc-main" v-else-if="formType == 'support'">
		<h3 class="heading heading__hr">Вопрос технической поддержке</h3>
		<ask-question-form :is_support_form="true"/>
	</main>
</template>

<script>
import AskQuestionForm from '@/components/forms/AskQuestionForm.vue'
export default {
	data() {
		return {
			form_type: this.$route.meta.form_type
		}
	},
	computed: {
		formType() {
			return this.$route.meta.form_type
		}
	},
	components: {
		'ask-question-form': AskQuestionForm
	}
}
</script>

<style>

</style>
